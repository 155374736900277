<script setup lang="ts">
import { ref, ComputedRef, computed, watch, Ref, reactive, onMounted } from 'vue'
import { useSubmissionsStore, getFieldsName } from '../../stores/submissions'
import { useI18NStore } from '../../stores/i18n'
import { useFormsStore } from '../../stores/forms'
import BaseLayout from '../../layouts/BaseLayout.vue'
import ContentSection from '../../components/base/ContentSection.vue'
import BasicButton from '../../components/commons/BasicButton.vue'
import IconCircleBox from '../../components/commons/IconCircleBox.vue'
import ModalBox from '../../components/commons/modal/ModalBox.vue'
import SubmissionForm from '../../components/forms/SubmissionForm.vue'
import PanelCard from '../../components/commons/PanelCard.vue'
import LabelText from '../../components/commons/LabelText.vue'
import StickyWrapper from '../../components/commons/StickyWrapper.vue'
import HistoryList from '../../components/commons/HistoryList.vue'
import { FORM_TYPES, SUBMISSIONS_STATUS_VALUE, SUBMISSIONS_STATUS_SUCCESS_MESSAGE } from '../../_constants/common.constant'
import { HistoryListData, SubmissionAction } from '../../_types/components/commons/form'
import { useSukuBudgetStore } from '../../stores/sukubudget'
import { useModal } from '../../stores/modal'
import { useAppStore } from '../../stores/app'
import { useAuthStore } from '../../stores/auth'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import SubmissionMessage from '../../components/commons/SubmissionMessage.vue'
import { ButtonTheme } from '../../components/commons/button'
import { componentTheme } from '../../_constants/theme.constant'
import { findDifferentFields, toRawDeep } from '../../_helpers/common.helpers'
import { useIdaFormsStore } from '../../stores/idaforms'
import { FormKitSchemaFormKit } from '@formkit/core'

const router = useRouter()
const toast = useToast()
const submissionStore = useSubmissionsStore()
const formStore = useFormsStore()
const idaFormStore = useIdaFormsStore()
const modal = useModal()
const sukuBudgetStore = useSukuBudgetStore()
const showConfirmationModal = ref(false)
const showAuditTrailDetail = ref(false)
const actionType = ref<SubmissionAction>('')
const i18Store = useI18NStore()
const authStore = useAuthStore()
const submissionFormRef: Ref<typeof SubmissionForm | null> = ref(null)

const props = defineProps<{
  formKey: string,
}>()

const formData: Record<string, any> = ref({})
const uploadedFiles = ref([])
const schema = ref<FormKitSchemaFormKit>() // formkit schema that populated from `SubmissionForm` as a emit

const isLoading = ref(false)

const labelTextThemes: Record<string, componentTheme> = {
  [SUBMISSIONS_STATUS_VALUE.verified]: 'success',
  [SUBMISSIONS_STATUS_VALUE.rejected]: 'error',
  [SUBMISSIONS_STATUS_VALUE.newSubmission]: 'default',
  [SUBMISSIONS_STATUS_VALUE.changesRequested]: 'warning'
}

const submission = computed(() => {
  return { ...submissionStore.activeSubmission, ...submissionStore.activeRevisionSubmission }
})

/**
 * If we update the repeater field, it will automatically update the `submission` on the store.
 * and break change detection, so we make a clone of the original fields for comparison later
 */
const initialSubmissionValues = structuredClone(toRawDeep(submission.value.fields))

await formStore.setActiveSubmission(props.formKey)
const formType = ref(submission.value?.form_type ?? '')
const formDef = computed(() => {
  return (formStore.forms)
    ? formStore.forms.find(it => it.key === formType.value)
    : null
})

const isFormUpdated = ref<boolean>(false) // if there is a new document that being added, this will have `true` value

// audit trail search form
const auditTrailFilters = reactive({
  keyword: '',
  status: []
})

/**
 * get submission history
 */

onMounted(async () => {
  await idaFormStore.initialize()
  formSchema.value = await idaFormStore.generateFormSchema(formType.value)
})

const formSchema: Ref<FormKitSchemaFormKit | undefined> = ref(undefined)

const submissionHistory: ComputedRef<HistoryListData[]> = computed(() => submissionStore.getSubmissionHistoryWithMessages(props.formKey, formSchema.value))
const historyOptions = computed(() => {
  const reverseHistory = submissionHistory.value.slice(1)
  const options = [
    {
      value: 0,
      label: i18Store.gettext('Latest changes')
    }
  ]
  const historyFormatted = reverseHistory.map((history, index) => ({
    value: history.id,
    label: `${i18Store.gettext('Revision')} ${index + 1}, ${history.date}`
  }))
  return [...options, ...historyFormatted]
})

/**
 * set active revision and current revision
 */
const activeRevision = ref(historyOptions.value[0].value ?? 0)

/**
 * disable all actions button
 */
const disabledActionButtons: ComputedRef<boolean> = computed(() => {
  return (activeRevision.value !== 0) || (submission.value?.status !== SUBMISSIONS_STATUS_VALUE.newSubmission && submission.value?.status !== SUBMISSIONS_STATUS_VALUE.changesRequested)
})

/**
 * disable save button if there is not changes
 */
const disabledSaveButton: ComputedRef<boolean> = computed(() => {
  let isFormDataUpdated = findDifferentFields(formData.value, initialSubmissionValues as Record<string, any>) // findDifferent fields, but exclude the field that doesnt exist in both object
  if (schema.value) {
    // get formkit type, exclude the field differences if its a `hidden` and `repeater`
    const formKitType = getFieldsName(schema.value, isFormDataUpdated, '$formkit')
    isFormDataUpdated = isFormDataUpdated.filter((it, index) => formKitType[index] !== 'hidden' && formKitType[index] !== 'repeater') // should not compare the hidden and  parent repeater component
  }
  return (disabledActionButtons.value || isFormDataUpdated.length < 1) && !isFormUpdated.value
})

/**
 * trigger validation for the upload form
 */
function uploadFormValidation () {
  if (submission.value?.status === SUBMISSIONS_STATUS_VALUE.changesRequested) { // If status is `reopened`, then trigger upload form validation
    const hasUploadedDocs = submissionFormRef.value?.triggerUploadFormValidation() // validate uploadForm component
    if (!hasUploadedDocs) {
      return false
    }
  }
  return true
}

const verifyConfirmation = async () => {
  if (submission.value?.status === SUBMISSIONS_STATUS_VALUE.verified) {
    return
  }
  if (!uploadFormValidation()) return

  if ((submission.value.form_type === FORM_TYPES.POM1 || submission.value.form_type === FORM_TYPES.CFM12FF12 || submission.value.form_type === FORM_TYPES.CFM2FF4) && typeof submission.value?.key !== 'undefined' && typeof formData.value?.suco !== 'undefined' && typeof formData.value?.year !== 'undefined') {
    const existingFiscalYear = await sukuBudgetStore.checkExistingBudgetSubmissionData(submission.value.form_type, submission.value?.key, formData.value?.suco, formData.value?.year, formData.value?.month)
    if (existingFiscalYear.length > 0) {
      modal.open('confirm', {
        label: 'Reject',
        modalTitle: 'Fiscal year already exist!',
        modalCaption: 'Please click view data to see the existing data. Or please reject the submission.',
        actions: [
          {
            label: 'View existing data',
            icon: 'la-eye',
            buttonTheme: 'primaryOutline',
            callback: () => {
              window.open(`/form/${existingFiscalYear[0].submission_id}/`, '_blank')?.focus()
            }
          }
        ],
        callback: () => {
          actionType.value = 'reject'
          confirmationAction()
          modal.close()
        }
      })
      return
    }
  }
  showConfirmationModal.value = true
  actionType.value = 'verify'
}

/**
 * confrimation action methods.
 * there are some condition based on `actionType`
 */
const confirmationAction = async () => {
  const status = actionType.value === 'verify' ? 3 : 2
  if (!submission.value || submission.value?.status === SUBMISSIONS_STATUS_VALUE.verified || !submission.value.key || disabledActionButtons.value) {
    return
  }

  if (actionType.value !== 'update') { // procced to the next step based on action type
    const { data, error } = await submissionStore.setSubmissionStatus({
      status,
      form_key: submission?.value?.key
    })

    if (data !== undefined) { // show toast message if update submission's status is success
      toast.success({
        component: SubmissionMessage,
        listeners: {
          viewSubmission: async () => await router.push({
            name: 'formDetail',
            params: {
              formKey: props.formKey
            }
          })
        },
        props: {
          message: SUBMISSIONS_STATUS_SUCCESS_MESSAGE[status]
        }
      })
    }
    if (typeof error !== 'undefined') {
      console.error(error)
    }
  } else if (actionType.value === 'update') {
    if (disabledSaveButton.value) return // if save button is disable
    isLoading.value = true
    await submissionStore.updateSubmission(props.formKey, formType.value, formData.value)
    await formStore.uploadFiles(uploadedFiles.value)
    showConfirmationModal.value = false
    isLoading.value = false
  }
  // redirect page to the submission list page
  router.push('/form')
}

const rejectConfirmation = () => {
  showConfirmationModal.value = true
  actionType.value = 'reject'
}

const reOpenAction = async () => {
  if (typeof props.formKey === 'undefined') return
  modal.open('confirm', {
    label: 'Reopen',
    modalIcon: 'las la-exclamation-triangle',
    modalTitle: 'Are you sure you’d like to reopen this submission?',
    modalCaption: 'By reopen this Form submission it will become editable.',
    callback: async () => {
      useAppStore().$state.loading = true
      const { data } = await submissionStore.setSubmissionStatus({
        status: SUBMISSIONS_STATUS_VALUE.changesRequested,
        form_key: props.formKey
      })

      if (data !== undefined) {
        toast.success({
          component: SubmissionMessage,
          listeners: {
            viewSubmission: async () => await router.push({
              name: 'formDetail',
              params: {
                formKey: props.formKey
              }
            })
          },
          props: {
            message: SUBMISSIONS_STATUS_SUCCESS_MESSAGE[SUBMISSIONS_STATUS_VALUE.changesRequested]
          }
        })
      }
      useAppStore().$state.loading = false
      modal.close()
    }
  })
}

/**
 * event listener when revision field is change
 * @param value revision value
 */
const onChangeRevision = async (value: unknown) => {
  if (typeof value !== 'number') {
    return
  }
  resetRevisedFields()
  if (value === 0) { // if user select the latest changes, it will reset the activeRevisionSubmission = activeSubmission staet
    await submissionStore.setActiveSubmission(props.formKey)
    await submissionStore.setActiveRevision()
    return
  }

  const selectedRevision = submissionStore.activeSubmissionHistory?.filter(submission => submission.version === value)
  if (Array.isArray(selectedRevision) && selectedRevision?.length > 0) {
    const currentRevision = selectedRevision[0]
    submissionStore.setActiveRevision(currentRevision)
  }
}

/**
 * event listener when user click audit trail button
 * will call the history api to get the latest history
 */
const showAuditTrail = async () => {
  await submissionStore.getSubmissionHistory(props.formKey)
  showAuditTrailDetail.value = true
}

/**
 * temporary changes for highlighting the field that have revised
 */
const revisedFields = computed(() => submissionStore.revisedFields)
const resetRevisedFields = () => {
  document.querySelectorAll('.hasRevised').forEach(field => {
    field.classList.remove('hasRevised')
  })
}

watch(revisedFields, (newValue) => {
  if (activeRevision.value === 0) {
    return
  }
  newValue?.forEach(field => {
    let fieldName = field
    if (field.includes('.')) { // for repeater component
      const splitFieldName = fieldName.split('.')
      fieldName = splitFieldName[splitFieldName.length - 1]

      const fieldIndex = splitFieldName[1]

      const inputs = document.querySelector('.submission-form-wrapper')?.querySelectorAll(`[name="${fieldName}"]`)
      if (inputs !== undefined && fieldIndex !== undefined) {
        inputs[Number(fieldIndex)]?.closest('.formkit-outer')?.classList.add('hasRevised')
      }
    } else {
      const findInput = document.querySelector('.submission-form-wrapper')?.querySelector(`[name="${fieldName}"]`) ?? document.querySelector('.submission-form-wrapper')?.querySelector(`#${fieldName}`)
      findInput?.closest('.formkit-outer')?.classList.add('hasRevised')
    }
  })
})

/**
 * update confirmation, will display confirmation popup
 */
const saveConfirmation = () => {
  if (disabledSaveButton.value) return

  if (!uploadFormValidation()) return

  showConfirmationModal.value = true
  actionType.value = 'update'
}

interface ConfirmationProps {
  iconClass: string
  icon: string
  title: string
  caption: string
  ctaTheme: ButtonTheme
  ctaText: string
}

/**
 * confirmation modal text and classes
 */
const confirmationModalProps: Record<string, ConfirmationProps> = {
  verify: {
    iconClass: 'border-emerald-400',
    icon: 'la-stamp',
    title: 'Are you sure you’d like to verify Form submission?',
    caption: 'By verifying this Form submission it will become active in the system.',
    ctaTheme: 'primary',
    ctaText: 'Verify'
  },
  reject: {
    iconClass: 'border-red-300',
    icon: 'la-times',
    title: 'Are you sure you’d like to reject Form submission?',
    caption: 'By rejecting this Form submission it will become inactive in the system.',
    ctaTheme: 'red',
    ctaText: 'Reject'
  },
  update: {
    iconClass: 'border-emerald-600',
    icon: 'la-stamp',
    title: 'Are you sure you’d like to update the Form submission?',
    caption: 'By update this Form submission it will be stored in our history system.',
    ctaTheme: 'primary',
    ctaText: 'Update'
  }
}

</script>

<template>
  <base-layout
    class="pb-[90px] submission-form-wrapper"
    :loading="isLoading"
  >
    <template #title>
      <div class="flex items-center">
        <router-link
          to="/form/"
          class="text-xl font-bold inline-flex items-center"
        >
          <icon-circle-box class="border-emerald-600 mr-4">
            <i class="las la-angle-left"></i>
          </icon-circle-box>
        </router-link>
        <h5
          class="text-xl lg:text-[38px] leading-tight font-black inline-flex items-center lg:whitespace-nowrap"
        >
          {{ gettext(formDef?.name ?? '') }}
        </h5>
      </div>
    </template>
    <div
      v-if="!isLoading"
      class="flex flex-col-reverse lg:flex-row flex-wrap px-5 py-6 lg:px-10 xl:px-22 md:py-8 "
    >
      <div class="w-full lg:w-3/5 2xl:w-2/3 lg:pr-4 xl:pr-[33px]">
        <SubmissionForm
          ref="submissionFormRef"
          v-model="formData"
          v-model:files="uploadedFiles"
          v-model:is-form-updated="isFormUpdated"
          v-model:schema="schema"
          :form-key="formKey"
          :form-type="formType"
          :is-review="true"
          :disabled="disabledActionButtons"
          :required-upload-form="true"
        />
      </div>
      <div class="w-full lg:w-2/5 2xl:w-1/3 lg:pl-4 xl:pl-[33px] mb-5 lg:mb-0">
        <sticky-wrapper device="desktop">
          <panel-card class="shadow-none">
            <template #title>
              <h3 class="text-lg lg:text-xl font-bold flex">
                <i class="las la-exchange-alt text-emerald-600 mr-3 relative top-1"></i> {{ gettext('Form submission details') }}
              </h3>
            </template>
            <label-text
              class="text-2sm font-bold flex items-center"
              :theme="labelTextThemes[submission?.status ?? 1]"
            >
              <span v-if="submission?.status === SUBMISSIONS_STATUS_VALUE.verified">
                <i class="las la-check text-lg leading-none mr-3"></i> {{ gettext('Verified') }}
              </span>
              <span v-else-if="submission?.status === SUBMISSIONS_STATUS_VALUE.rejected">
                <i class="las la-times text-lg leading-none mr-3"></i> {{ gettext('Form rejected') }}
              </span>
              <span v-else-if="submission?.status === SUBMISSIONS_STATUS_VALUE.changesRequested">
                <i class="las la-ellipsis-h text-lg leading-none mr-3"></i> {{ gettext('Form reopened') }}
              </span>
              <span v-else>
                <i class="las la-ellipsis-h text-lg leading-none mr-3"></i> {{ gettext('Form created') }}
              </span>
            </label-text>
            <p class="mt-5 mb-3">
              {{ gettext('Revision') }}
            </p>
            <FormKit
              key="revision"
              v-model="activeRevision"
              type="select"
              name="revision"
              :placeholder="gettext('Select revision')"
              option-class="text-left"
              :options="historyOptions"
              @input="onChangeRevision"
            />
            <button
              type="button"
              class="text-emerald-600 font-bold flex items-center mt-6"
              @click="showAuditTrail"
            >
              <i class="las la-map-signs text-2xl mr-2"></i> {{ gettext("View audit trail") }}
            </button>
          </panel-card>
        </sticky-wrapper>
      </div>
    </div>
    <content-section class="bg-stone-100 fixed z-50 bottom-0 right-0 w-full lg:w-[calc(100%-260px)] border-t border-t-zinc-400 !py-0">
      <basic-button
        v-if="submission?.status === SUBMISSIONS_STATUS_VALUE.verified && authStore.can('form_submission.can_approve_reject_submission')"
        class="!w-full xs:max-w-[220px] my-5 !px-6"
        @click="reOpenAction"
      >
        {{ gettext('Reopen') }}
      </basic-button>
      <div
        v-else
        class="grid grid-cols-3 sm:grid-cols-4 gap-3 md:gap-5"
      >
        <basic-button
          v-if="authStore.can('form_submission.can_approve_reject_submission')"
          class="my-5 !px-6 flex-col xs:flex-row"
          :class="{ '!bg-green-300': submission?.status === SUBMISSIONS_STATUS_VALUE.verified }"
          :disabled="disabledActionButtons || !disabledSaveButton"
          @click="verifyConfirmation"
        >
          <i class="las la-check text-base xs:text-2xl xs:mr-2 lg:mr-6"></i>
          <span class="text-2xs xs:text-base leading-tight">{{ submission?.status === SUBMISSIONS_STATUS_VALUE.verified ? gettext('Verified') : gettext('Verify') }}</span>
        </basic-button>
        <basic-button
          v-if="authStore.can('form_submission.can_approve_reject_submission')"
          :disabled="disabledActionButtons || !disabledSaveButton"
          class="my-5 !px-6 flex-col xs:flex-row"
          theme="primaryOutline"
          @click="rejectConfirmation"
        >
          <i class="las la-times text-base xs:text-2xl sm:mr-2"></i>
          <span class="text-2xs xs:text-base leading-tight">{{ gettext('Reject') }}</span>
        </basic-button>
        <basic-button
          theme="light"
          class="my-5 w-full !px-6"
          :disabled="disabledSaveButton"
          @click="saveConfirmation"
        >
          {{ gettext('Save') }}
        </basic-button>
      </div>
    </content-section>
    <modal-box
      v-if="showConfirmationModal"
      class="text-center"
      size="lg"
    >
      <span
        class="w-[56px] h-[56px] md:w-[60px] md:h-[60px] flex items-center justify-center mx-auto rounded-full bg-white border-2 text-dark"
        :class="confirmationModalProps[actionType].iconClass"
      >
        <i
          class="las text-2xl"
          :class="confirmationModalProps[actionType].icon"
        ></i>
      </span>
      <h2 class="text-1.5xl md:text-3.5xl font-bold mt-6 leading-tight">
        {{ gettext(confirmationModalProps[actionType].title) }}
      </h2>
      <p class="mt-4">
        {{ gettext(confirmationModalProps[actionType].caption) }}
      </p>
      <basic-button
        size="lg"
        :theme="confirmationModalProps[actionType].ctaTheme"
        class="mt-7 md:w-full md:max-w-[324px] !h-[50px] md:!h-[60px] !text-base md:!text-lg"
        @click="confirmationAction"
      >
        <i class="las la-check text-3xl mr-4"></i>
        {{ gettext(confirmationModalProps[actionType].ctaText) }}
      </basic-button>
      <basic-button
        size="lg"
        class="mt-6 mb-5 md:w-full md:max-w-[324px] !h-[50px] md:!h-[60px] !text-base md:!text-lg"
        theme="text"
        @click="() => showConfirmationModal = false"
      >
        {{ gettext('Cancel') }}
      </basic-button>
    </modal-box>
    <modal-box
      v-if="showAuditTrailDetail"
      class="text-center"
      size="xl"
      spacing="md"
    >
      <template #header>
        <h3 class="text-lg lg:text-3.5xl leading-tight font-bold flex">
          <i class="text-2xl lg:text-3.5xl las la-map-signs mr-2 text-emerald-600 relative lg:top-1"></i> {{ interpolate(gettext('%(formName)s audit trail'), {formName: formDef?.name}, true) }}
        </h3>
        <div class="mt-3 md:mt-5 flex flex-wrap items-center">
          <FormKit
            v-model="auditTrailFilters.keyword"
            outer-class="w-full md:w-auto md:min-w-[227px] !mb-0"
            type="search"
            prefix-icon="search"
            :placeholder="gettext('Search')"
          />
          <div class="w-full md:w-auto mt-4 md:mt-0 md:pl-4">
            <FormKit
              v-model="auditTrailFilters.status"
              type="checkbox"
              input-class="hidden peer"
              decorator-class=" las la-times ml-2 relative !hidden peer-checked:!inline-block"
              wrapper-class="cursor-pointer relative flex flex-row-reverse items-center rounded-1.5xl border border-zinc-400 text-[13px] py-1 px-4 mr-2 formkit data-[checked=true]:bg-zinc-400 data-[checked=true]:border-zinc-600"
              options-class="flex"
              name="type"
              :options="[
                {
                  label: gettext('Revised'),
                  value: 'revised'
                },
                {
                  label: gettext('Verified'),
                  value: 'verified'
                },
                {
                  label: gettext('Rejected'),
                  value: 'rejected'
                }
              ]"
            />
          </div>
        </div>
        <button
          type="button"
          class="fixed top-5 right-5 text-2xl"
          @click="() => showAuditTrailDetail = false"
        >
          <i class="las la-times"></i>
        </button>
      </template>
      <div class="h-full relative max-h-[54vh] md:max-h-[55vh] overflow-y-auto pb-5">
        <HistoryList
          :filter="auditTrailFilters"
          :datas="submissionHistory"
        />
      </div>
    </modal-box>
  </base-layout>
</template>

<style lang="postcss">
.hasRevised {
  &[data-type="text"], &[data-type="number"] {
    input {
      @apply !border-amber-400 !bg-amber-400/10;
    }
  }
  &[data-type="select"] {
    select {
      @apply !border-amber-400 !bg-amber-400/10;
    }
  }
  &[data-type="datepicker"] {
    .formkit-inner {
      @apply !border-amber-400 !bg-amber-400/10;
    }
  }
}
</style>
