import { RouteLocationRaw } from 'vue-router'
import { permissionCode } from '../stores/auth'
import { OptionsType } from '../_types/components/forms/select-dropdown'
interface SubMenuContext {
  to: string
  label: string
  permissions: permissionCode[]
}

export interface MenuContext {
  to?: string
  parentMenu?: string[]
  label: string
  permissions?: permissionCode[]
  subMenus?: SubMenuContext[]
}

interface SukuManagerMenuContext {
  label: string
  value: string
  icon: string
  iconColor: string
  permissions?: permissionCode[]
}

export const MAIN_MENUS: MenuContext[] = [
  {
    to: '/',
    label: 'Home'
  },
  {
    label: 'Dashboard',
    parentMenu: ['/dashboard/'],
    permissions: ['metabase.view_mbreportdashboard'],
    subMenus: [
      {
        to: '/dashboard/43/',
        label: 'Infographic',
        permissions: ['metabase.view_mbreportdashboard']
      },
      {
        to: '/dashboard/42/',
        label: 'Social',
        permissions: ['metabase.view_mbreportdashboard']
      },
      {
        to: '/dashboard/45/',
        label: 'Finance',
        permissions: ['metabase.view_mbreportdashboard']
      },
      {
        to: '/dashboard/47/',
        label: 'Technical',
        permissions: ['metabase.view_mbreportdashboard']
      },
      {
        to: '/dashboard/44/',
        label: 'M & E',
        permissions: ['metabase.view_mbreportdashboard']
      }
    ]
  },
  {
    to: '/suku-manager/',
    label: 'Suku manager',
    permissions: [
      'pnds_data.view_zsuco'
    ]
  },
  {
    to: '/projects/',
    label: 'Projects manager',
    permissions: [
      'ida.view_project',
      'ida.change_project'
    ]
  },
  {
    to: '/complaints/',
    label: 'Complaints manager',
    permissions: [
      'ida.view_complaint',
      'ida.change_complaint'
    ]
  },
  {
    label: 'Forms',
    parentMenu: ['/form/', '/submit/'],
    permissions: [
      'form_submission.read_submissionform',
      'form_submission.add_submissionform',
      'form_submission.approve_submissionform',
      'form_submission.reject_submissionform'
    ],
    subMenus: [
      {
        to: '/submit/',
        label: 'Data entry',
        permissions: ['form_submission.add_submissionform']
      },
      {
        to: '/form/',
        label: 'Submissions',
        permissions: [
          'form_submission.read_submissionform',
          'form_submission.approve_submissionform',
          'form_submission.reject_submissionform'
        ]
      }
    ]
  },
  {
    label: 'Admin',
    parentMenu: ['/manage/'],
    permissions: [
      'ida.view_idauser',
      'auth.view_group',
      'ida_options.change_activity',
      'form_submission.approve_submissionform',
      'form_submission.reject_submissionform'
    ],
    subMenus: [
      {
        to: '/manage/user/',
        label: 'Users',
        permissions: ['ida.view_idauser']
      },
      {
        to: '/manage/group/',
        label: 'Groups',
        permissions: ['auth.view_group']
      },
      {
        to: '/manage/options/',
        label: 'Options',
        permissions: ['ida_options.change_activity']
      },
      {
        to: '/manage/submission_history/',
        permissions: [
          'form_submission.approve_submissionform',
          'form_submission.reject_submissionform'
        ],
        label: 'Submission History'
      },
      {
        to: '/manage/idaforms/',
        permissions: [
          'form_submission.approve_submissionform',
          'form_submission.reject_submissionform'
        ],
        label: 'Forms Manager'
      }

    ]
  }
]

export const SUKU_MANAGER_MENU: SukuManagerMenuContext[] = [{
  label: 'Profile',
  value: 'profile',
  icon: 'la-list-alt',
  iconColor: 'bg-purple-500'
},
{
  label: 'Priorities',
  value: 'priorities',
  icon: 'la-icons',
  iconColor: 'bg-orange-500',
  permissions: [
    'pnds_data.add_sucopriorities',
    'pnds_data.change_sucopriorities',
    'pnds_data.view_sucopriorities'
  ]
},
{
  label: 'Operational budget',
  value: 'operational-budget',
  icon: 'la-coins',
  iconColor: 'bg-green-250',
  permissions: [
    'ida_forms.view_pom_1',
    'ida_forms.change_pom_1',
    'ida_forms.add_pom_1'
  ]
},
{
  label: 'Infrastructure budget',
  value: 'infrastructure-budget',
  icon: 'la-coins',
  iconColor: 'bg-green-250',
  permissions: [
    'ida_forms.view_pom_1',
    'ida_forms.change_pom_1',
    'ida_forms.add_pom_1'
  ]
},
{
  label: 'Suppliers',
  value: 'suppliers',
  icon: 'la-handshake',
  iconColor: 'bg-amber-400',
  permissions: [
    'ida.add_supplier',
    'ida.change_supplier',
    'ida.view_supplier'
  ]
},
{
  label: 'Complaints',
  value: 'complaints',
  icon: 'la-tools',
  iconColor: 'bg-[#F63DB4]',
  permissions: [
    'ida.add_complaint',
    'ida.change_complaint',
    'ida.view_complaint'
  ]
},
{
  label: 'Activities',
  value: 'activities',
  icon: 'la-comment-dots',
  iconColor: 'bg-[#007BDD]'
},
{
  label: 'Project Management Teams',
  value: 'management-teams',
  icon: 'la-users',
  iconColor: 'bg-emerald-600'
},
{
  label: 'Planning and Accountability Commision - KPA',
  value: 'kpa',
  icon: 'la-users',
  iconColor: 'bg-emerald-600'
},
{
  label: 'Suku facilitators',
  value: 'suku-facilitators',
  icon: 'la-users',
  iconColor: 'bg-emerald-600'
}]

export const OPTIONS_MENU: OptionsType = [
  {
    value: 'activity',
    label: 'Activity'
  },
  {
    value: 'munisipiu',
    label: 'Municipality'
  },
  {
    value: 'aldeia',
    label: 'Aldeia'
  },
  {
    value: 'suku',
    label: 'Suco'
  },
  {
    value: 'postuadministrativu',
    label: 'Administrative Post'
  },
  {
    value: 'meetingobjective',
    label: 'Meeting objective'
  },
  {
    value: 'activitytype',
    label: 'Activity type'
  },
  {
    value: 'category',
    label: 'Category'
  },
  {
    value: 'cmtposition',
    label: 'Position'
  },
  {
    value: 'cycle',
    label: 'Project activity'
  },
  {
    value: 'documentfiletype',
    label: 'File type'
  },
  {
    value: 'documenttype',
    label: 'Document type'
  },
  {
    value: 'electionround',
    label: 'Election round'
  },
  {
    value: 'financedisbursementtype',
    label: 'Finance disbursement type'
  },
  {
    value: 'financemonitor',
    label: 'Finance monitor'
  },
  {
    value: 'financialdisbursementactivity',
    label: 'Financial disbursement activity'
  },
  {
    value: 'fundingsource',
    label: 'Funding source'
  },
  {
    value: 'gender',
    label: 'Gender'
  },
  {
    value: 'indicator',
    label: 'Indicator'
  },
  {
    value: 'month',
    label: 'Month'
  },
  {
    value: 'objective',
    label: 'Objective'
  },
  {
    value: 'operationbudgetstatus',
    label: 'Operational budget status'
  },
  {
    value: 'output',
    label: 'Project output'
  },
  {
    value: 'pndsfreebalancetranslation',
    label: 'Finance label'
  },
  {
    value: 'programactivity',
    label: 'Program activity'
  },
  {
    value: 'programactivitylevel',
    label: 'Program activity level'
  },
  {
    value: 'programactivitytype',
    label: 'Program activity type'
  },
  {
    value: 'reporttype',
    label: 'Report type'
  },
  {
    value: 'sector',
    label: 'Sector'
  },
  {
    value: 'subprojectstatus',
    label: 'Subproject status'
  },
  {
    value: 'subprojectstatus1',
    label: 'Project status'
  },
  {
    value: 'subsector',
    label: 'Subsector'
  },
  {
    value: 'sucophase',
    label: 'Suco phase'
  },
  {
    value: 'sucostatus',
    label: 'Suco status'
  },
  {
    value: 'teamposition',
    label: 'Team position'
  },
  {
    value: 'teampositiongroup',
    label: 'Team position group'
  },
  {
    value: 'unit',
    label: 'Unit'
  },
  {
    value: 'year',
    label: 'Year'
  },
  {
    value: 'yesno',
    label: 'Yes-no'
  }
]
